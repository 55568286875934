/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import noop from 'lodash/noop';
import debounce from 'lodash/debounce';

import { getSourceType } from 'utils/assets';
import { trackVisibilityChange, isPlaying, getVimeoPlayerSrc, getYoutubePlayerSrc, PlayerProps } from './helpers';

// Start video at 1 second to avoid black or empty initial frame if no cover image is provided
const START_TIME = 1;

export const VideoPlayer = (props: PlayerProps) => {
	const { isEditableMode, autoplay } = props;
	const [isVisible, setIsVisible] = React.useState<boolean | undefined>(undefined);
	const [isReady, setIsReady] = React.useState(false);
	const iframeLoadAbortControllerRef = React.useRef<AbortController | null>(null);
	const containerRef = React.useRef<HTMLDivElement>(null);
	const videoRef = React.useRef<HTMLVideoElement>(null);
	const youtubeRef = React.useRef<HTMLIFrameElement>(null);
	const vimeoRef = React.useRef<HTMLIFrameElement>(null);
	const type = getSourceType(props.src);
	const reloadAttempts = React.useRef({ value: 0, max: 3 });

	const onIframeLoad = () => {
		if (isEditableMode || !autoplay || !isVisible) {
			return;
		}

		iframeLoadAbortControllerRef.current?.abort();
		iframeLoadAbortControllerRef.current = new AbortController();

		switch (type) {
			case 'youtube': {
				youtubeRef.current?.contentWindow?.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
				break;
			}

			case 'vimeo': {
				vimeoRef.current?.contentWindow?.postMessage('{"method":"play"}', '*');
				window.addEventListener(
					'message',
					event => {
						if (event.origin !== 'https://player.vimeo.com') return;

						const data = JSON.parse(event.data);
						if (data.event === 'ready') {
							vimeoRef.current?.contentWindow?.postMessage('{"method":"play"}', '*');
						}
					},
					{ signal: iframeLoadAbortControllerRef.current.signal }
				);
				break;
			}

			default: {
				break;
			}
		}
	};

	React.useEffect(function cleanupIframeLoad() {
		return () => {
			iframeLoadAbortControllerRef.current?.abort();
		};
	}, []);

	React.useEffect(
		function trackVisibility() {
			const intervalDuration = isEditableMode ? 2500 : 200;
			const visibilityHandler = (cb?: (visible: boolean) => void) => (visible: boolean) => {
				if (isVisible === visible) return;

				cb?.(visible);
				setIsVisible(visible);
				if (!visible) setIsReady(false);
			};
			let cleanupTrackVisibilityChange = noop;

			switch (type) {
				case 'video':
					cleanupTrackVisibilityChange = trackVisibilityChange(
						containerRef.current,
						visibilityHandler(),
						intervalDuration
					);
					break;

				case 'vimeo':
					cleanupTrackVisibilityChange = trackVisibilityChange(
						containerRef.current,
						visibilityHandler(visible => {
							if (visible && autoplay) {
								vimeoRef.current?.contentWindow?.postMessage('{"method":"play"}', '*');
							} else {
								vimeoRef.current?.contentWindow?.postMessage('{"method":"pause"}', '*');
								vimeoRef.current?.contentWindow?.postMessage(
									'{"method":"setCurrentTime","value":"0"}',
									'*'
								);
							}
						}),
						intervalDuration
					);
					break;

				case 'youtube':
					cleanupTrackVisibilityChange = trackVisibilityChange(
						containerRef.current,
						visibilityHandler(visible => {
							if (visible && autoplay) {
								youtubeRef.current?.contentWindow?.postMessage(
									'{"event":"command","func":"playVideo","args":""}',
									'*'
								);
							} else {
								youtubeRef.current?.contentWindow?.postMessage(
									'{"event":"command","func":"stopVideo","args":""}',
									'*'
								);
							}
						}),
						intervalDuration
					);
					break;

				default:
					break;
			}

			return () => {
				cleanupTrackVisibilityChange();
			};
		},
		[isEditableMode, isVisible, autoplay, type]
	);

	React.useEffect(() => {
		if (type === 'video' && isVisible) {
			if (videoRef.current?.readyState === 0) {
				console.info('VideoPlayer:load');
				videoRef.current.load();
			} else if (isReady && autoplay) {
				console.info('VideoPlayer:play');
				videoRef.current?.play().catch(error => console.error('VideoPlayer:play', error));
			}
		}
	}, [type, isVisible, isReady, autoplay]);

	return (
		<div
			data-visible={isVisible}
			style={{ width: '100%', height: '100%', objectFit: 'inherit', objectPosition: 'inherit' }}
			ref={containerRef as React.RefObject<HTMLDivElement>}
		>
			{isVisible && (
				<>
					{type === 'youtube' && (
						<iframe
							title={props.title}
							src={getYoutubePlayerSrc(props)}
							width="100%"
							height="100%"
							// eslint-disable-next-line max-len
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowFullScreen
							onLoad={onIframeLoad}
							ref={youtubeRef}
						/>
					)}

					{type === 'vimeo' && (
						<iframe
							title={props.title}
							src={getVimeoPlayerSrc(props)}
							width="100%"
							height="100%"
							allowFullScreen
							onLoad={onIframeLoad}
							ref={vimeoRef}
						/>
					)}

					{type === 'video' ? (
						<video
							title={props.title}
							src={`${props.src}${props.poster ? '' : `#t=${START_TIME}`}`}
							poster={props.poster}
							controls={props.controls}
							muted={props.muted}
							loop={props.loop}
							preload={autoplay ? 'auto' : 'metadata'}
							autoPlay={autoplay}
							playsInline={props.autoplay}
							controlsList="nodownload"
							ref={videoRef}
							onCanPlay={() => {
								console.info('VideoPlayer:onCanPlay');
								setIsReady(true);
							}}
							onPlay={event => {
								if (videoRef.current && Math.floor(videoRef.current.currentTime) <= START_TIME) {
									videoRef.current.currentTime = 0;
								}

								[...document.querySelectorAll('video')].forEach(video => {
									if (video !== event.currentTarget && isPlaying(video) && !video.muted) {
										console.info('VideoPlayer:pause:other');
										video.pause();
									}
								});
							}}
							onError={debounce(event => {
								console.error('VideoPlayer:onError', event);

								if (isVisible && reloadAttempts.current.value < reloadAttempts.current.max) {
									console.info('=== VideoPlayer:reload ===', reloadAttempts.current.value);
									videoRef.current?.load();
									reloadAttempts.current.value += 1;
								}
							}, 300)}
						/>
					) : null}
				</>
			)}
		</div>
	);
};

export default VideoPlayer;
