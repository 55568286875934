import React from 'react';
import _ from 'lodash';
import type { BBCommonProps } from 'types/story';
import { TRANSITION_TYPE, COMPONENT_TYPE } from 'common/constants';
import cardCSS from 'client/components/common/BuildingBlocks/Card/Card.scss';
import transitionSCSS from 'client/components/common/BuildingBlocks/Transition.scss';
import floatAboveCSS from 'client/components/common/BuildingBlocks/FloatAbove/FloatAbove.scss';
import { isEditableMode } from 'client/components/common/BuildingBlocks/utils/common';
import { withBuildingBlock } from './WithBuildingBlock';

const TRANSITION_PRESET = {
	common: {
		[TRANSITION_TYPE.NONE]: {
			base: transitionSCSS.defaultBase,
			in: transitionSCSS.defaultIn,
			out: transitionSCSS.defaultOut,
		},
		[TRANSITION_TYPE.FADE]: {
			base: transitionSCSS.fadeBase,
			in: transitionSCSS.fadeIn,
			out: transitionSCSS.fadeOut,
		},
		[TRANSITION_TYPE.ZOOM]: {
			base: transitionSCSS.zoomBase,
			in: transitionSCSS.zoomIn,
			out: transitionSCSS.zoomOut,
		},
	},
	[COMPONENT_TYPE.CARD]: {
		[TRANSITION_TYPE.ZOOM]: {
			base: cardCSS.zoomBase,
			in: cardCSS.zoomIn,
			out: cardCSS.zoomOut,
		},
	},
	[COMPONENT_TYPE.CONTENT]: {},
	[COMPONENT_TYPE.FLOAT_ABOVE]: {
		[TRANSITION_TYPE.FADE]: {
			base: floatAboveCSS.fadeBase,
			in: floatAboveCSS.fadeIn,
			out: floatAboveCSS.fadeOut,
		},
		[TRANSITION_TYPE.ZOOM]: {
			base: floatAboveCSS.zoomBase,
			in: floatAboveCSS.zoomIn,
			out: floatAboveCSS.zoomOut,
		},
	},
	[COMPONENT_TYPE.FLOAT_BELOW]: {
		[TRANSITION_TYPE.FADE]: {
			base: floatAboveCSS.fadeBase,
			in: floatAboveCSS.fadeIn,
			out: floatAboveCSS.fadeOut,
		},
		[TRANSITION_TYPE.ZOOM]: {
			base: floatAboveCSS.zoomBase,
			in: floatAboveCSS.zoomIn,
			out: floatAboveCSS.zoomOut,
		},
	},
};

interface WithExposeProps
	extends Pick<
		BBCommonProps,
		| 'children'
		| 'currentMediaQuery'
		| 'editorMode'
		| 'getComputedComponentProps'
		| 'editableModeProps'
		| 'in'
		| 'isCardTree'
		| 'mediaQuery'
		| 'onTransitionEnd'
		| 'symbol'
		| 'type'
		| 'uiConfig'
		| '_id'
	> {
	Component: ReturnType<typeof withBuildingBlock>;
}

export type WithExposeProvidedProps = {
	transitionCSS: BBCommonProps['transitionCSS'];
	resetChildrenTransition: BBCommonProps['resetChildrenTransition'];
};

class WithExpose extends React.Component<WithExposeProps> {
	static displayName: string;

	childrenTransitions: ReturnType<BBCommonProps['resetChildrenTransition']> = {};

	constructor(props: WithExposeProps) {
		super(props);
		this.childrenTransitions = this.resetChildrenTransition();
	}

	componentDidUpdate(prevProps: WithExposeProps) {
		if (this.props.in !== prevProps.in) {
			this.childrenTransitions = this.resetChildrenTransition();
			if (!this.props.uiConfig.expose?.effect && !_.size(this.childrenTransitions)) {
				this.props.onTransitionEnd?.({ ...this.props, in: this.props.in });
			}
		}
	}

	onChildTransitionEnd: BBCommonProps['onTransitionEnd'] = ({ _id }) => {
		if (this.childrenTransitions[_id]) {
			return;
		}

		this.childrenTransitions[_id] = true;

		if (_.values(this.childrenTransitions).every(Boolean)) {
			// every children transition is complete
			this.props.onTransitionEnd?.({ ...this.props, in: this.props.in });
		}
	};

	get isEditableMode() {
		return isEditableMode(this.props);
	}

	get transitionCSS(): BBCommonProps['transitionCSS'] {
		const { type, in: inProp } = this.props;
		const expose = this.props.uiConfig.expose?.effect || TRANSITION_TYPE.FADE;
		const preset: typeof TRANSITION_PRESET.common.none = _.merge(
			{},
			TRANSITION_PRESET.common?.[expose],
			TRANSITION_PRESET[type]?.[expose]
		);
		const transitionBaseCSS = preset.base;
		const transitionTypeCSS = preset[inProp ? 'in' : 'out'];
		const transitionCSS = { [transitionBaseCSS]: true, [transitionTypeCSS]: inProp !== undefined };

		return !this.isEditableMode && this.props.isCardTree ? transitionCSS : null;
	}

	resetChildrenTransition = () => {
		const children = React.Children.toArray(this.props.children);
		return _.reduce<ArrayType<typeof children>, Record<string, boolean>>(
			children,
			(acc, child) => {
				if (React.isValidElement<BBCommonProps>(child) && child.props._id) acc[child.props._id] = false;
				return acc;
			},
			{}
		);
	};

	renderChildren = (): React.ReactNode => {
		const { children } = this.props;
		const props = { onTransitionEnd: this.onChildTransitionEnd };

		if (typeof children !== 'string') {
			return React.Children.map(children, child =>
				React.isValidElement(child) ? React.cloneElement(child, props) : null
			);
		}

		return children;
	};

	render() {
		const { Component, ...props } = this.props;

		return (
			<Component
				{...props}
				transitionCSS={this.transitionCSS}
				resetChildrenTransition={this.resetChildrenTransition}
			>
				{this.renderChildren()}
			</Component>
		);
	}
}

/**
 * HOC for supporting CSSTransition between cards.
 * Each BuildingBlock receives callback which should be called when its transition finished.
 * Every parent creates its own one and passes to children,
 * only when all children finish their transitions — callback is called
 */
function withExpose<P>(Component: React.ComponentType<P>) {
	const WrappedComponent = withBuildingBlock(Component);

	const HOC: React.FC<Omit<WithExposeProps, 'Component' | keyof WithExposeProvidedProps>> = props => (
		<WithExpose {...props} Component={WrappedComponent}>
			{props.children}
		</WithExpose>
	);

	HOC.displayName = `withExpose(${Component.displayName || Component.name})`;

	return HOC;
}

export { withExpose };
