import React, { RefObject, useContext } from 'react';
import cn from 'classnames';
import type { BBCommonProps } from 'types/story';
import { UNIFORM_PROPS } from 'common/constants/component-props';
import { CardFacade } from 'common/utils/facades/card-facade';
import { useClientSelector } from 'client/reducers';
import { selectStoryCardSettings } from 'client/reducers/story/selectors';
import { selectTotalAnswersByCard, selectUserAnswersByCard } from 'client/reducers/user/selectors';
import AnimatedNumber from 'client/components/common/AnimatedNumber';
import { CardRendererContext } from 'client/components/common/StoryCard/CardRenderer/context';
import withCardTransitionContext from 'client/components/common/BuildingBlocks/BuildingBlockEnhancer';
import css from './AnswerProgress.scss';

export enum AnswerProgressType {
	bar = 'bar',
	num = 'num',
}

type ProgressComponentProp = {
	max: number;
	cur: number;
	progress: number;
	id: string;
};

export const AnswerProgressDataSelector = {
	progress: 'answer-progress',
	progressTrack: 'answer-progress-track',
};

const ProgressBar: React.FC<ProgressComponentProp> = (props: React.ComponentProps<typeof ProgressBar>) => (
	<div className={cn(css.bar)}>
		<div className={css.barTrack} data-selector={AnswerProgressDataSelector.progressTrack}>
			<div
				className={css.barProgress}
				data-selector={AnswerProgressDataSelector.progress}
				style={{ transform: `scaleX(${props.progress})` }}
			/>
		</div>
	</div>
);

const ProgressNum: React.FC<ProgressComponentProp> = (props: React.ComponentProps<typeof ProgressNum>) => (
	<div className={css.numText}>
		<AnimatedNumber number={props.cur} />
		<span className={css.numTextDivider}>/</span>
		<AnimatedNumber number={props.max} />
	</div>
);

const componentMap = {
	[AnswerProgressType.bar]: ProgressBar,
	[AnswerProgressType.num]: ProgressNum,
};

const AnswerProgress = (props: BBCommonProps) => {
	const { editableModeProps: EMP, stateAttrs, uiConfig } = props;
	const type = uiConfig.componentProps[UNIFORM_PROPS.answerProgressType];

	const { card, nextDefaultCardId } = useContext(CardRendererContext)!;
	const { _id: cardId, type: cardType } = card;
	const userAnswers = useClientSelector(state => selectUserAnswersByCard(state, cardId));
	const storyCardSettings = useClientSelector(state => selectStoryCardSettings(state, cardId));
	const answersMax = CardFacade.getAnswersMax(cardType, storyCardSettings);
	const answersCur = useClientSelector(state => selectTotalAnswersByCard(state, cardId));
	const progress = props.isEditableMode ? 0.4 : answersCur / answersMax;
	const isHidden = !nextDefaultCardId && userAnswers?.submitted;

	if (!type) {
		return null;
	}

	const Component = componentMap[type];

	return (
		<div
			{...uiConfig.nodeProps}
			{...stateAttrs}
			{...props.eventListeners}
			{...EMP?.nodeProps}
			style={{ ...uiConfig.nodeProps?.style, ...(isHidden ? { visibility: 'hidden' } : null) }}
			className={cn(
				css.progress,
				css[`progress-${type}`],
				uiConfig.nodeProps.className,
				EMP?.nodeProps?.className
			)}
			ref={props.containerRef as RefObject<HTMLDivElement>}
		>
			<Component max={answersMax} cur={answersCur} progress={progress} id={props._id} />
		</div>
	);
};

export default withCardTransitionContext(AnswerProgress);
