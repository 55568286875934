import React from 'react';
import _ from 'lodash';
import produce from 'immer';
import type { BBCommonProps } from 'types/story';
import { isTouch } from 'common/utils/environment';
import { COMPONENT_STATES, COMPONENT_TYPE, DISABLED_ANSWER_ATTR } from 'common/constants';
import { isEditableMode, getStateDOMAttrs } from 'client/components/common/BuildingBlocks/utils/common';
import { withAnimation } from './WithAnimation';

interface WithBuildingBlockProps
	extends Pick<
		BBCommonProps,
		| 'children'
		| 'currentMediaQuery'
		| 'editableModeProps'
		| 'editorMode'
		| 'getComputedComponentProps'
		| 'in'
		| 'isCardTree'
		| 'mediaQuery'
		| 'onTransitionEnd'
		| 'resetChildrenTransition'
		| 'symbol'
		| 'transitionCSS'
		| 'type'
		| 'uiConfig'
		| '_id'
		| 'parentStates'
	> {
	Component: ReturnType<typeof withAnimation>;
}

type WithBuildingBlockProvidedProps = {
	states: BBCommonProps['states'];
	setStates: BBCommonProps['setStates'];
	stateAttrs: BBCommonProps['stateAttrs'];
	eventListeners: BBCommonProps['eventListeners'];
	isEditableMode: BBCommonProps['isEditableMode'];
};

type WithBuildingBlockState = {
	states: BBCommonProps['states'];
};

class WithBuildingBlock extends React.Component<WithBuildingBlockProps, WithBuildingBlockState> {
	static displayName: string;

	constructor(props: WithBuildingBlockProps) {
		super(props);

		this.onEditableMouseEnter = this.onEditableMouseEnter.bind(this);
		this.onEditableMouseLeave = this.onEditableMouseLeave.bind(this);
		this.onEditableMouseDown = this.onEditableMouseDown.bind(this);
		this.onEditableFocus = this.onEditableFocus.bind(this);
		this.onEditableMouseDownCapture = this.onEditableMouseDownCapture.bind(this);
		this.onDoubleClickCapture = this.onDoubleClickCapture.bind(this);

		// building block current states (hover, correct, etc.)
		const initialStates: WithBuildingBlockState['states'] = _.reduce(
			_.omit(COMPONENT_STATES, ['DEFAULT']),
			(memo, value) => _.set(memo, value, false),
			{} as WithBuildingBlockState['states']
		);

		this.state = {
			states: { ...initialStates, ...(props.parentStates ?? {}) },
		};
	}

	static getDerivedStateFromProps({ parentStates }: WithBuildingBlockProps, state: WithBuildingBlockState) {
		// Update states with a parentStates
		return parentStates ? { states: { ...state.states, ...parentStates } } : null;
	}

	onEditableMouseEnter(event: React.MouseEvent<Element, MouseEvent>) {
		_.invoke(this.props.editableModeProps, 'eventListeners.onMouseEnter', event, this.props);
	}

	onEditableMouseLeave(event: React.MouseEvent<Element, MouseEvent>) {
		_.invoke(this.props.editableModeProps, 'eventListeners.onMouseLeave', event);
	}

	onEditableMouseDown(event: React.MouseEvent<Element, MouseEvent>) {
		_.invoke(this.props.editableModeProps, 'eventListeners.onMouseDown', event, this.props);
	}

	onEditableFocus(event: React.FocusEvent) {
		_.invoke(this.props.editableModeProps, 'eventListeners.onFocus', event, this.props);
	}

	onEditableMouseDownCapture(event: React.MouseEvent<Element, MouseEvent>) {
		_.invoke(this.props.editableModeProps, 'eventListeners.onMouseDownCapture', event, this.props);
	}

	onDoubleClickCapture(event: React.MouseEvent<Element, MouseEvent>) {
		_.invoke(this.props.editableModeProps, 'eventListeners.onDoubleClickCapture', event, this.props);
	}

	/**
	 * NOTE:
	 *   Unfortunately changing state doesn't update this.props.uiConfig.componentProps and after state changed
	 *   component will still receive computed props as it in default state.
	 *   This becomes a problem when some component inside of own code depends on "uiConfig.componentProps",
	 *   which are won't re-compute on state change.
	 *   (!) Think about, how to re-compute props for a component after his state is changed.
	 *
	 * NOTE:
	 *   Solution (!!!) -> see usage of this.props.getComputedComponentProps
	 */
	setStates: BBCommonProps['setStates'] = states => {
		this.setState(prevState => ({ states: { ...prevState.states, ...states } }));
	};

	/**
	 * this.state.states converted as data attributes to assign onto DOM node
	 */
	get stateAttrs(): BBCommonProps['stateAttrs'] {
		if (_.isEmpty(this.state.states)) {
			console.error(`<${this.constructor.name} /> has no states assigned!`);
		}
		return getStateDOMAttrs(this.state.states);
	}

	get eventListeners(): BBCommonProps['eventListeners'] {
		const isUnselectable =
			this.props.type === COMPONENT_TYPE.CONTENT ||
			this.props.type === COMPONENT_TYPE.FLOAT_ABOVE ||
			this.props.type === COMPONENT_TYPE.FLOAT_BELOW;

		if (this.isEditableMode) {
			if (isUnselectable) {
				return undefined;
			}

			return {
				onMouseEnter: this.onEditableMouseEnter,
				onMouseLeave: this.onEditableMouseLeave,
				onMouseDown: this.onEditableMouseDown,
				onFocus: this.onEditableFocus,
				onMouseDownCapture: this.onEditableMouseDownCapture,
				onDoubleClickCapture: this.onDoubleClickCapture,
			};
		}

		const { HOVER, SELECTED, SELECTED_HOVER } = COMPONENT_STATES;
		const disabledAnswerAttr = `[${DISABLED_ANSWER_ATTR}="true"]`;

		// Non-editor listeners
		return {
			onMouseEnter: event => {
				if (isUnselectable || isTouch || (event.target as Element).closest(disabledAnswerAttr)) {
					return;
				}

				this.setState(
					produce(draft => {
						_.set(draft, ['states', HOVER], true);
						_.set(draft, ['states', SELECTED_HOVER], draft.states[SELECTED]);
					})
				);
			},
			onMouseLeave: e => {
				if (isUnselectable || isTouch) return;

				this.setState(
					produce(draft => {
						_.set(draft, ['states', HOVER], false);
						_.set(draft, ['states', SELECTED_HOVER], false);
					})
				);
			},
		};
	}

	get isEditableMode() {
		return isEditableMode(this.props);
	}

	render() {
		const { Component, ...props } = this.props;

		const bbProps = {
			states: this.state.states,
			setStates: this.setStates,
			stateAttrs: this.stateAttrs,
			eventListeners: this.eventListeners,
			isEditableMode: this.isEditableMode,
			uiConfig: props.uiConfig,
		};

		/*
		 Re-compute component props for a current(not default) state

		 IMPORTANT:
		 When `this.setStates` is called, traverse-tree won't be re-called, so `uiConfig.componentProps`
		 won't be re-computed for a current(new) state and will contain values computed for a `defaultState`.
		 Next lines of code are fixing this behaviour. Checking is there is active some state that is not "default",
		 and if yes, `componentProps` will be re-computed and result assigned to BB props
		 */
		if (!bbProps.isEditableMode) {
			const stateKeys = Object.keys(bbProps.states) as (keyof WithBuildingBlockState['states'])[];
			const currentState = stateKeys.filter(key => bbProps.states[key])?.[0];
			if (currentState) {
				bbProps.uiConfig = produce(props.uiConfig, acc => {
					acc.componentProps = props.getComputedComponentProps(currentState).value;
				});
			}
		}

		return (
			<Component {...props} {...bbProps}>
				{props.children}
			</Component>
		);
	}
}

/**
 * HOC with basic functionality for all BuildingBlocks
 */
function withBuildingBlock<P>(Component: React.ComponentType<P>) {
	const WrappedComponent = withAnimation(Component);

	const HOC: React.FC<Omit<WithBuildingBlockProps, 'Component' | keyof WithBuildingBlockProvidedProps>> = props => (
		<WithBuildingBlock {...props} Component={WrappedComponent}>
			{props.children}
		</WithBuildingBlock>
	);

	HOC.displayName = `withBuildingBlock(${Component.displayName || Component.name})`;

	return HOC;
}

export { withBuildingBlock };
