import { debounce, defer, findIndex, forOwn } from 'lodash';
import produce from 'immer';
import type { MouseEvent as ReactMouseEvent } from 'react';
import { IFRAME_ACTIONS, transmitTo } from 'utils/iframe-tunnel';
import { SELECTION_TYPES } from 'client/components/common/SelectionHint/utils';
import { EditorMode, SelectedBB } from 'types/story';
import { setEditorSelectedNode } from 'client/actions';
import type { SelectedHintEventDetail } from 'client/components/common/SelectionHint/SelectionHintEvent';
import type { HandleThunkActionCreator } from 'react-redux';
import type { SelectionSingleType } from 'client/components/common/SelectionHint/types';
import { COMPONENT_TYPE } from 'common/constants';

const pushClickedElementToAdmin = debounce((payload: SelectedBB) => {
	transmitTo({
		id: SelectionHintController.name || 'SelectionHint',
		target: 'admin',
		action: IFRAME_ACTIONS.SET_EDITABLE_EDITOR_ELEMENT,
		payload,
	});
}, 50);

const getSelectedComponentProps = (componentProps: SelectedHintEventDetail['componentProps']) =>
	componentProps === null
		? componentProps
		: {
				_id: componentProps._id,
				type: componentProps.type,
				uiConfig: componentProps.uiConfig,
				editableModeProps: componentProps.editableModeProps,
				symbol: componentProps.symbol,
				children:
					componentProps.type === COMPONENT_TYPE.TEXT && typeof componentProps.children === 'string'
						? componentProps.children
						: null,
			};

const isMouseEvent = (e: SelectedHintEventDetail['originalEvent']): e is ReactMouseEvent => {
	return 'nativeEvent' in e && (e as ReactMouseEvent).nativeEvent instanceof MouseEvent;
};

export class SelectionHintController {
	static FOCUS = 'focus' as const;

	static SELECT = 'select' as const;

	static DESELECT = 'deselect' as const;

	action: HandleThunkActionCreator<typeof setEditorSelectedNode>;

	multiSelections: Array<SelectionSingleType>;

	constructor(props: { action: HandleThunkActionCreator<typeof setEditorSelectedNode> }) {
		this.action = props.action;
		this.multiSelections = [];
	}

	/**
	 * Use it just to update SelectionHint view (NOTE: Supported only single selected node)
	 */
	focus = (
		e: SelectedHintEventDetail['originalEvent'],
		type: SelectedHintEventDetail['selectionType'],
		compProps: Exclude<SelectedHintEventDetail['componentProps'], null>
	) => {
		const id = e.currentTarget?.id;
		const selectedComponentProps = getSelectedComponentProps(compProps);
		if (!id || !selectedComponentProps) {
			return;
		}
		this.action({ type: SELECTION_TYPES.clicked, data: { id, ...selectedComponentProps } });
	};

	/**
	 * Use it to update SelectionHint view and admin state of the selected element
	 * (NOTE: Supported single|multiple selected node)
	 */
	select = async (
		e: SelectedHintEventDetail['originalEvent'],
		type: SelectedHintEventDetail['selectionType'],
		compProps: SelectedHintEventDetail['componentProps'],
		stage: SelectedHintEventDetail['stage'],
		editorMode: EditorMode
	) => {
		let selection;
		const isMultiSelect = e.shiftKey && type === SELECTION_TYPES.clicked && editorMode !== EditorMode.CONTENT;
		const selectedComponentProps = getSelectedComponentProps(compProps);

		if (!selectedComponentProps || !compProps) {
			return;
		}

		const id = e.currentTarget.id ?? compProps.uiConfig.nodeProps.id;
		const data = { id, selectionStage: stage, ...selectedComponentProps };

		if (!isMouseEvent(e) && e.totalSelected > 1) {
			/*
			 Wait until all selected node are being updated and then push to the store all of them in a one bunch.
			 When elements are updated traverseTree provides an updates to the components and each related to component
			 <SelectedComponentWatcher> in his didUpdate lifecycle triggers select event on myself
			 */

			/*
			 Why can be old index?
			 may be caused when update triggered more than one time on the same node, but shouldn't happen
			 */
			const oldIndex = findIndex(this.multiSelections, ['id', id]);

			if (oldIndex > -1) this.multiSelections[oldIndex] = data;
			else this.multiSelections.push(data);

			if (this.multiSelections.length === e.totalSelected) {
				// Store data in "redux" to render SelectionHint component based on it
				selection = await this.action({ type, isMultiSelect, data: this.multiSelections });
				// clear multiSelection
				this.multiSelections = [];
			} else {
				return;
			}
		} else {
			// Store data in "redux" to render SelectionHint component based on it
			selection = await this.action({ type, isMultiSelect, data });
		}

		if (type === SELECTION_TYPES.clicked) {
			// Transmit to admin CardEditor computed data + inheritance of the clicked element
			const selectionClicked = selection[SELECTION_TYPES.clicked];

			/* eslint-disable no-param-reassign */
			const payload = produce(selectionClicked, draft => {
				forOwn(draft, selectedComponent => {
					const path = selectedComponent.editableModeProps.nodeProps?.['data-path'];
					const { inheritance } = selectedComponent.editableModeProps;

					selectedComponent.inheritance = inheritance;
					selectedComponent.path = path;

					delete selectedComponent.id;
					delete selectedComponent.editableModeProps;
				});
			});
			/* eslint-enable no-param-reassign */

			// why defer: finish all tasks with a current element and then select another one
			defer(() => {
				pushClickedElementToAdmin(payload as unknown as SelectedBB);
			});
		}
	};

	deselect = (e: SelectedHintEventDetail['originalEvent'], type: SelectedHintEventDetail['selectionType']) => {
		this.action({ type });

		if (type === SELECTION_TYPES.clicked || !type) {
			pushClickedElementToAdmin({});
		}
	};
}
