import request from 'superagent';
import { reduce } from 'lodash';
import { Endpoint } from './endpoint';
import { IInterceptorOptions, SendOptions } from './types';

/**
 * Request interceptor
 * @callback RequestInterceptor
 * @param {object} request - the request object
 * @param {object} requestOptions - request options
 * @return request
 */

/**
 * Response interceptor
 * @callback ResponseInterceptor
 * @param {object} request - request object
 * @param {object} response - response object
 * @param {object} requestOptions - request options
 * @return response
 */

export class Http {
	/**
	 * @static send - send web request
	 * @param  endpoint     	    request endpoint
	 * @param  sendOptions       	request options
	 * @param  sendOptions.data 	request body data (for use in post, put requests)
	 * @param  sendOptions.query 	request query data (?key1=value1&key2=value2)
	 * @param  sendOptions.headers  request headers
	 * @return resolves with response after passing through response interceptors
	 */
	static send<T extends any = any>(endpoint: Endpoint, sendOptions?: SendOptions): Promise<T> {
		const method = endpoint.method.toLowerCase();
		const options: Partial<Endpoint['options'] & SendOptions> = {
			...endpoint.options,
			...sendOptions,
		};
		const { requestInterceptors, responseInterceptors, errorInterceptors } = options;

		// get request object
		let req = request[method](endpoint.fullUrl);

		// apply request requestInterceptors
		if (Array.isArray(requestInterceptors)) {
			req = reduce(
				requestInterceptors,
				(_req, interceptor) => interceptor(_req, options as IInterceptorOptions),
				req
			);
		}

		// add request payload
		if (options && 'data' in options) {
			req = req.send(options.data);
		}

		// add query string
		if (options && 'query' in options) {
			req = req.query(options.query);
		}

		// set headers
		if (options.headers) {
			req.set(options.headers);
		}

		const re = req.then(res => {
			if (Array.isArray(responseInterceptors)) {
				return reduce(
					responseInterceptors,
					(_res, interceptor) => interceptor(req, _res, options as IInterceptorOptions),
					res
				);
			}
			return res;
		});

		if (Array.isArray(errorInterceptors)) {
			re.catch(err =>
				reduce(
					errorInterceptors,
					(_err, interceptor) => interceptor(req, _err, options as IInterceptorOptions),
					err
				)
			);
		}

		return re;
	}
}
