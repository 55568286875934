import React, { createContext, useContext } from 'react';

function createGenericContext<T>(defaultValue: T) {
	const Context = createContext<T | undefined>(defaultValue);

	const GenericContextProvider = ({ children, value = defaultValue }: { children: React.ReactNode; value: T }) => {
		return <Context.Provider value={value}>{children}</Context.Provider>;
	};

	const useGenericContext = (): T => {
		const context = useContext(Context);
		if (!context) {
			throw new Error('useGenericContext must be used within a Provider');
		}
		return context;
	};

	const GenericContextConsumer = ({ children }: { children: (contextValue: T) => React.ReactNode }) => {
		return (
			<Context.Consumer>
				{contextValue => {
					if (contextValue === undefined) {
						throw new Error('GenericContextConsumer must be used within a Provider');
					}
					return children(contextValue);
				}}
			</Context.Consumer>
		);
	};

	// Higher-Order Component for class components
	const withGenericContext = (Component: React.ComponentType<any>) => {
		return function WithContextComponent(props: any) {
			return (
				<Context.Consumer>
					{contextValue => {
						if (contextValue === undefined) {
							throw new Error('withContext must be used within a Provider');
						}
						return <Component {...props} context={contextValue} />;
					}}
				</Context.Consumer>
			);
		};
	};

	return [useGenericContext, GenericContextProvider, GenericContextConsumer, withGenericContext] as const;
}

export default createGenericContext;
